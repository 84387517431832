<template>
  <div class="main3">
    <div v-if="isSubmited" class="body2">
      <img class="xuanzhong" src="../../assets/img/xuanzhong.png" alt="" />
      
      <div class="tijiao">已提交</div>

      <van-button class="choice-btn" style="margin-top:100px" @click="back()">返回直播间</van-button>

      <!-- <div class="look">查看参会学员 电子学分证书领取及查询流程</div>
      <img class="look-img" src="https://sho-static.shulan.com/activities/infected/tip-1.jpeg" alt="" />
      <img class="look-img" src="https://sho-static.shulan.com/activities/infected/tip-2.jpeg" alt="" />
      <img class="look-img" src="https://sho-static.shulan.com/activities/infected/tip-3.jpeg" alt="" /> -->
      <div style="height:30px"></div>
    </div>
    <div v-else class="body">
      <div class="title">
        {{ title }}
      </div>

      <!-- subjectType null 类型 0-单选,1-多选, 地区3 日期4 -->

      <div v-for="item in subjects" :key="item.subjectId">
        <!-- 0-单选 -->
        <div v-if="item.subjectType == 0" class="choice-container">
          <div class="choice-title">
            {{ item.subjectContent }}
          </div>
          <van-radio-group v-model="item.userAnswer" direction="horizontal">
            <van-radio
              checked-color="#12BEAE"
              v-for="option in item.subjectOptions"
              :key="option.id"
              class="choice-item" 
              :name="option.id"
              >{{ option.optionContent }}</van-radio
            >
          </van-radio-group>
        </div>
        <!-- 1-多选 -->
        <div v-if="item.subjectType == 1" class="choice-container">
          <div class="choice-title">
            {{ item.subjectContent }}
          </div>
          <van-checkbox-group v-model="item.userAnswer" direction="horizontal">
            <!-- <van-checkbox checked-color="#12BEAE" class="choice-item" name="a"
              >复选框 a</van-checkbox
            > -->
            <van-checkbox
              checked-color="#12BEAE"
              v-for="option in item.subjectOptions"
              :key="option.id"
              class="choice-item"
              :name="option.id"
              >{{ option.optionContent }}</van-checkbox
            >
          </van-checkbox-group>
        </div>
        <!-- 2-填空  -->
        <div v-if="item.subjectType == 2" class="choice-container">
          <div class="choice-title">
            {{ item.subjectContent }}
          </div>
          <van-field
            class="choice-input"
            v-model="item.userAnswer"
            rows="1"
            type="textarea"
            :placeholder="item.remark"
          />
        </div>
      </div>

      <div class="tip">点击下方提交，完成答题</div>
      <van-button class="choice-btn" @click="submit">提 交</van-button>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { Field, Button, Toast } from "vant";
import { RadioGroup, Radio } from "vant";
import Vue from "vue";
import axios from "axios";
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  components: {},
  data() {
    return {
      qid: "",
      isSubmited: false,
      checkboxvalue: [],
      radio: "",
      isMobile: false,
      subjects: [],
      title: "",
    };
  },
  created() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // alert("yd");
      this.isMobile = true;
    } else {
      // alert("pc");
      this.isMobile = false;
      console.log("11", this.isMobile);
    }
  },
  mounted() {
    this.checkLoginState();
  },

  methods: {
    checkLoginState() {
      this.isLogin = localStorage.getItem("tuid") && localStorage.getItem("tuid") != 0;
      if (this.isLogin) {
        this.qid = this.$route.params.qid;
        this.visitId = this.$route.params.visitId;
        this.userSource = this.$route.params.userSource;
        this.getQuestionnaireDetail();
        this.checkSubmitState();

      } else {
        alert("请从直播间进入答题页面");
      }
    
    },
    back() {
      this.$router.back();
    },

    checkSubmitState() {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/questionnaire/record/info/byQuestionnaireIdAndUser?userId=${localStorage.getItem("tuid")}&userSource=${this.userSource}&questionnaireId=${this.qid}`, )
        .then((res) => {
          Toast.clear();
          console.log("res", res);
          if (res.data.questionnaireRecordId) {
            this.isSubmited = true;
          } else {
            if(!this.visitId) {
              alert("请从直播间进入答题页面");
            }
          }
        })
        .catch(() => {
          Toast.clear();
        });
    },

    getQuestionnaireDetail() {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/questionnaire/get/by/id?questionnaireId=${this.qid}`)
        .then((res) => {
          Toast.clear();
          const { title, subjects } = res.data;
          this.title = title;
          this.subjects = subjects;
        })
        .catch(() => {
          Toast.clear();
        });
    },

    submit() {
      if(!this.visitId) {
              alert("请从直播间进入答题页面");
              return;
      }
      var subject = [];
      console.log("subjects", this.subjects);
      for (const index in this.subjects) {
        const element = this.subjects[index];
        if (element.userAnswer) {
          if (element.subjectType == 0) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: JSON.stringify([element.userAnswer]),
              subjectResult: element.subjectOptions
                .filter((e) => element.userAnswer == e.id)
                .map((e) => e.optionContent)
                .join(","),
            });
          } else if (element.subjectType == 1) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: JSON.stringify(element.userAnswer),
              subjectResult: element.subjectOptions
                .filter((e) => element.userAnswer.indexOf(e.id) != -1)
                .map((e) => e.optionContent)
                .join(","),
            });
          } else if (element.subjectType == 2) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: element.userAnswer,
              subjectResult: element.userAnswer,
            });
          }

          console.log("subject", subject);
        } else {
          this.$toast.fail("请完成所有试题再提交");
          return;
        }
      }
      Toast.loading({
        message: "正在提交...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/get/request/token`)
        .then((res) => {
          console.log("token", res);
          this.$axios
            .post(`/audio/video/visit/questionnaire/submit`, {
                reqToken: res.data,
                visitId: this.visitId,
                questionnaireId: this.qid,
                sourceChannel: "h5",
                subject
            })
            .then((res) => {
              this.isSubmited = true
              Toast.clear();
            })
            .catch(() => {
              Toast.clear();
              this.$toast.fail("提交失败，请重新提交");
            });
        })
        .catch(() => {
          Toast.clear();
          this.$toast.fail("提交失败，请重新提交");
        });
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  background: #fff;
}
.main3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

@media only screen and (min-width: 1000px) {
  @function fit($num) {
    @return $num / 2.56;
  }

  .body {
    height: 100%;
    width: fit(816px);
    margin-bottom: fixt(100px);
  }

  .title {
    margin: fit(100px) 0 fit(50px);
    font-size: fit(48px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
  }

  .choice-container {
    margin: 0 auto;
    width: fit(750px);
  }
  .choice-title {
    margin: fit(30px);
    font-size: fit(30px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
  }
  .choice-item {
    padding-left: fit(56px);
    min-width: fit(315px);
    max-width: fit(650px);

    // min-height: fit(70px);
    // max-height: fit(200px);
    font-size: fit(30px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    margin: 0 0 fit(30px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

  }
  .choice-input {
    width: fit(700px);
    margin: 0 auto;
    background: #f5f5f9;
    height: fit(160px);
    font-size: fit(30px);
  }
  .tip {
    margin: fit(50px) auto 0;
    color: #12beae;
    text-align: center;
    font-size: fit(25px);
    font-family: PingFangSC-Normal, PingFang SC;
  }
  .choice-btn {
    background: #12beae;
    border-radius: fit(10px);
    width: fit(650px);
    height: fit(70px);
    margin: 5px auto 30px;
    line-height: fit(70px);
    text-align: center;
    font-size: fit(25px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: block;
  }
  .body2 {
    height: 100%;
    width: fit(816px);
    margin-bottom: fixt(100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .xuanzhong {
    margin: fit(150px) auto fit(18px);
    width: fit(90px);
    height: fit(90px);
    display: block;
  }
  .tijiao {
    font-size: fit(44px);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
  }
  .van-radio__label {
    line-height: fit(75px);
  }
  .van-checkbox__label{
    line-height: fit(75px);
  }
    .van-radio__icon{
    margin-top: 3px;
  }

  .look{
    font-size: fit(32px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1D1D1D;
  }
  .look-img{
    display: block;
    width: 100%;
    
  }
}

@media only screen and (max-width: 1000px) {
  @function fit($num) {
    @return $num;
  }
  .body {
    height: 100%;
    width: 100%;
  }

  .title {
    margin: 20px 10px 10px;
    font-size: fit(48px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
  }

  .choice-container {
    margin: 0 auto;
    width: fit(750px);
  }
  .choice-title {
    margin: fit(30px);
    font-size: fit(30px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
  }
  .choice-item {
    padding-left: fit(56px);
    min-width: fit(315px);
    max-width: fit(650px);
    font-size: fit(30px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    margin: 0 0 fit(30px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .choice-input {
    width: fit(700px);
    margin: 0 auto;
    background: #f5f5f9;
    height: fit(160px);
    font-size: 30px;
  }
  .tip {
    margin: fit(50px) auto 0;
    color: #12beae;
    text-align: center;
    font-size: fit(25px);
    font-family: PingFangSC-Normal, PingFang SC;
  }
  .choice-btn {
    background: #12beae;
    border-radius: fit(10px);
    width: fit(690px);
    height: fit(94px);
    margin: 10px auto 100px;
    line-height: fit(70px);
    text-align: center;
    font-size: fit(25px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: block;
  }
  .body2 {
    height: 100%;
    width: fit(816px);
    margin-bottom: fixt(100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .xuanzhong {
    margin: fit(150px) auto fit(18px);
    width: fit(90px);
    height: fit(90px);
    display: block;
  }
  .tijiao {
    font-size: fit(44px);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    margin-bottom: 66px;
  }

  .van-radio__label {
    line-height: fit(25px);
  }
  .van-radio__icon{
    margin-top: 3px;
  }

  .look{
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1D1D1D;
    line-height: 45px;
  }
  .look-img{
    display: block;
    width: 100%;
    
  }
}
</style>
